
import {
  ProductAttributeImage,
  ProductDetail,
  AttributeSelected,
  ProductAttribute,
} from "@/types/product/ProductDetail.interface";
import { defineComponent, ref, PropType, onMounted } from "vue";

export default defineComponent({
  props: {
    productDetail: {
      required: true,
      type: Object as PropType<ProductDetail>,
    },
  },
  emits: ["onGetProductConfig"],
  setup(props, { emit }) {
    let idxImg = ref<number>(-1);
    const selectedAttr = ref<AttributeSelected[]>([]);
    const attributes = ref<ProductAttribute[]>([]);

    const handleCheckOptionAttribute = (
      attributeId?: number,
      optionId?: number
    ) => {
      if (typeof attributeId !== 'undefined' && typeof optionId !== 'undefined')
        onCheckOptionAttribute(attributeId, optionId);

      setProductOutOfStock();
      setImgSelected();
      loopCheckProductOutOfStock();
    };

    const onCheckOptionAttribute = (
      attributeId?: number,
      optionId?: number
    ) => {
      if (selectedAttr.value.find((att) => att.attributeId == attributeId)) {
        selectedAttr.value.forEach((attri, i) => {
          if (attri.attributeId == attributeId) {
            return (selectedAttr.value[i].optionId = optionId);
          }
        });
      } else {
        selectedAttr.value.push({
          attributeId: attributeId,
          optionId: optionId,
        });
      }
    };

    const setProductOutOfStock = async () => {
      let countChildAttri = props.productDetail.attributes.length;
      await props.productDetail.children.attributeOption.forEach(
        async (el1) => {
          if (countChildAttri - 1 != 0) {
            if (el1.id == selectedAttr.value[0].optionId) {
              await el1.attribute.attributeOption.forEach(async (el2) => {
                if (countChildAttri - 2 != 0) {
                  if (el2.id == selectedAttr.value[1].optionId) {
                    await el2.attribute.attributeOption.forEach(async (el3) => {
                      if (countChildAttri - 3 != 0) {
                        if (el3.id == selectedAttr.value[2].optionId) {
                          await el3.attribute.attributeOption.forEach(
                            async (el4) => {
                              if (countChildAttri - 4 != 0) {
                                props.productDetail.attributes[3].option.forEach(
                                  (op, idx) => {
                                    if (el4.id == op.id) {
                                      attributes.value[3].option[
                                        idx
                                      ].isOutOfStock = el4.isOutOfStock;
                                    }
                                  }
                                );
                              } else {
                                props.productDetail.attributes[3].option.forEach(
                                  (op, idx) => {
                                    if (el4.id == op.id) {
                                      attributes.value[3].option[
                                        idx
                                      ].isOutOfStock = el4.isOutOfStock;
                                    }
                                  }
                                );
                              }
                            }
                          );
                        }
                        props.productDetail.attributes[2].option.forEach(
                          (op, idx) => {
                            if (el3.id == op.id) {
                              attributes.value[2].option[idx].isOutOfStock =
                                el3.isOutOfStock;
                            }
                          }
                        );
                      } else {
                        props.productDetail.attributes[2].option.forEach(
                          (op, idx) => {
                            if (el3.id == op.id) {
                              attributes.value[2].option[idx].isOutOfStock =
                                el3.isOutOfStock;
                            }
                          }
                        );
                      }
                    });
                  }
                  props.productDetail.attributes[1].option.forEach(
                    (op, idx) => {
                      if (el2.id == op.id) {
                        attributes.value[1].option[idx].isOutOfStock =
                          el2.isOutOfStock;
                      }
                    }
                  );
                } else {
                  props.productDetail.attributes[1].option.forEach(
                    (op, idx) => {
                      if (el2.id == op.id) {
                        attributes.value[1].option[idx].isOutOfStock =
                          el2.isOutOfStock;
                      }
                    }
                  );
                }
              });
            }
            props.productDetail.attributes[0].option.forEach((op, idx) => {
              if (el1.id == op.id) {
                attributes.value[0].option[idx].isOutOfStock = el1.isOutOfStock;
              }
            });
          } else {
            props.productDetail.attributes[0].option.forEach((op, idx) => {
              if (el1.id == op.id) {
                attributes.value[0].option[idx].isOutOfStock = el1.isOutOfStock;
              }
            });
          }
        }
      );
    };

    const checkFirstSelectedOutOfStock = async () => {
      let countChildAttri = props.productDetail.attributes.length;
      await props.productDetail.children.attributeOption.forEach(
        async (el1, i) => {
          if (el1.isOutOfStock) {
            if (attributes.value[0].option.length > i + 1) {
              onCheckOptionAttribute(
                attributes.value[0].id,
                attributes.value[0].option[i + 1].id
              );
            }
          } else {
            if (countChildAttri - 1 != 0) {
              await el1.attribute.attributeOption.forEach(async (el2, j) => {
                if (el2.isOutOfStock) {
                  if (attributes.value[1].option.length > j + 1) {
                    onCheckOptionAttribute(
                      attributes.value[1].id,
                      attributes.value[1].option[j + 1].id
                    );
                  }
                } else {
                  if (countChildAttri - 2 != 0) {
                    await el2.attribute.attributeOption.forEach(
                      async (el3, k) => {
                        if (el3.isOutOfStock) {
                          if (attributes.value[2].option.length > k + 1) {
                            onCheckOptionAttribute(
                              attributes.value[2].id,
                              attributes.value[2].option[k + 1].id
                            );
                          }
                        } else {
                          if (countChildAttri - 3 != 0) {
                            await el3.attribute.attributeOption.forEach(
                              async (el4, m) => {
                                if (el4.isOutOfStock) {
                                  if (
                                    attributes.value[3].option.length >
                                    m + 1
                                  ) {
                                    onCheckOptionAttribute(
                                      attributes.value[3].id,
                                      attributes.value[3].option[m + 1].id
                                    );
                                  }
                                }
                              }
                            );
                          }
                        }
                      }
                    );
                  }
                }
              });
            }
          }
        }
      );
    };

    const setFirstSelected = () => {
      props.productDetail.attributes.forEach((attr: ProductAttribute) => {
        onCheckOptionAttribute(attr.id, attr.option[0].id);
      });
    };

    const setImgSelected = async () => {
      let attributeMapperId = 0;
      await props.productDetail.children.attributeOption.forEach(
        async (el1) => {
          if (el1.id == selectedAttr.value[0].optionId) {
            attributeMapperId = el1.attributeMapperId;
            return;
          }
        }
      );
      addCartAttribute(attributeMapperId);
    };

    const addCartAttribute = (attributeMapperId: number) => {
      let idxImgTemp = props.productDetail.productAttributeImage.findIndex(
        (attrImg) => {
          return attrImg.attributeMapperId == attributeMapperId;
        }
      );
      idxImg.value = idxImgTemp;
    };

    const loopCheckProductOutOfStock = async () => {
      let countChildAttri = props.productDetail.attributes.length;
      let tempProduct = {} as ProductDetail;
      await props.productDetail.children.attributeOption.forEach(
        async (el1) => {
          if (el1.id == selectedAttr.value[0].optionId) {
            countChildAttri--;
            if (countChildAttri) {
              await el1.attribute.attributeOption.forEach(async (el2) => {
                if (el2.id == selectedAttr.value[1].optionId) {
                  countChildAttri--;
                  if (countChildAttri) {
                    await el2.attribute.attributeOption.forEach(async (el3) => {
                      if (el3.id == selectedAttr.value[2].optionId) {
                        countChildAttri--;
                        if (countChildAttri) {
                          await el3.attribute.attributeOption.forEach(
                            async (el4) => {
                              if (el4.id == selectedAttr.value[3].optionId) {
                                countChildAttri--;
                                if (countChildAttri) {
                                  console.log("over 4 level");
                                } else {
                                  tempProduct = el4.product;
                                  return;
                                }
                              }
                            }
                          );
                        } else {
                          tempProduct = el3.product;
                          return;
                        }
                      }
                    });
                  } else {
                    tempProduct = el2.product;
                    return;
                  }
                }
              });
            } else {
              tempProduct = el1.product;
              return;
            }
          }
        }
      );
      checkProductOutOfStock(tempProduct);
    };

    const checkProductOutOfStock = (flagProduct: ProductDetail) => {
      if (flagProduct.quantity < 1) {
        onGetProductConfig(
          flagProduct,
          true,
          props.productDetail.productAttributeImage[idxImg.value]
        );
      } else {
        onGetProductConfig(
          flagProduct,
          false,
          props.productDetail.productAttributeImage[idxImg.value]
        );
      }
    };

    const onGetProductConfig = (
      product: ProductDetail,
      isOutOfStock: boolean,
      productImages: ProductAttributeImage
    ) => {
      emit("onGetProductConfig", product, isOutOfStock, productImages);
    };

    onMounted(async () => {
      attributes.value = props.productDetail.attributes;
      await setFirstSelected();
      await handleCheckOptionAttribute();
    });

    return { handleCheckOptionAttribute, attributes, selectedAttr };
  },
});
