import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-as-center p-text-center p-m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isShowModal,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isShowModal) = $event)),
    modal: true,
    showHeader: false,
    dismissableMask: true,
    onHide: _ctx.onHideModalError,
    closable: "",
    class: "modal-error",
    ref: "modalAlertError"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString(_ctx.modalMessage), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onHide"]))
}