
import { computed, defineComponent, onMounted, ref } from "vue";
import ProductDetailService from "@/service/product-service/ProductDetailService";
import {
  ProductDetail,
  ProductDetailResponseDetail,
} from "@/types/product/ProductDetail.interface";
import { useRoute, useRouter } from "vue-router";
import ProductDetailPanel from "@/components/product/detail/ProductDetailPanel.vue";
import { Cart, UserCart } from "@/types/cart/Cart.interface";
import CartService from "@/service/cart-service/CartService";
import liff from "@line/liff";
import ModalAlertError from "@/components/modal/ModalAlertError.vue";
import { useStore } from "@/store";
import { CartActionTypes } from "@/store/modules/cart/action-types";
import { CartStorePayload } from "@/store/modules/cart/actions";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { onCloseFBBrowser } from "@/external/ExternalJavascript";

export default defineComponent({
  components: {
    ProductDetailPanel,
    ModalAlertError,
  },
  setup() {
    const productDetailService = ref(new ProductDetailService());
    const productDetail = ref<ProductDetail>();
    const cartDetail = ref<UserCart>();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const productSelected = ref<ProductDetail>();
    const cartService = ref(new CartService());
    const modalMessage = ref<string>("");
    const modalAlertError = ref<InstanceType<typeof ModalAlertError>>();

    const onHideModalError = () => {
      const payload = {
        branchId: store.getters.getBrand.branchId,
        socialReferenceId: store.getters.getUser.socialReferenceId,
      } as CartStorePayload;
      store.dispatch(CartActionTypes.GET_CART, payload);
    };

    const getProductDetail = async () => {
      // console.log("update")
      await productDetailService.value
        .getProductDetail(parseInt(route.params.id.toString()))
        .then((response: ProductDetailResponseDetail) => {
          productDetail.value = response.product;
          store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
        });
    };

    const beforeAddProductTocart = (
      product: ProductDetail,
      quantity: number,
      cartFlag: number
    ) => {
      if (store.getters.getPlatform == 0) {
        lineExternalLogin(product, quantity, cartFlag);
      } else {
        onAddProductToCart(product, quantity, cartFlag);
      }
    };

    const lineExternalLogin = async (
      product: ProductDetail,
      quantity: number,
      cartFlag: number
    ) => {
      await liff.init({ liffId: store.getters.getBrand.lineLiffId });
      if (liff.isLoggedIn()) {
        await getUserProfile();
        await store.dispatch(GlobalActionTypes.SET_TRANSACTION_PLATFORM, 1);
        onAddProductToCart(product, quantity, cartFlag);
      } else {
        liff.login({ redirectUri: window.location.href });
      }
    };

    const getUserProfile = async () => {
      const profile = await liff.getProfile();
    };

    const onAddProductToCart = async (
      product: ProductDetail,
      quantity: number,
      cartFlag: number
    ) => {
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      cartDetail.value = {
        referenceId: store.getters.getUser.socialReferenceId,
        typeId: store.getters.getPlatform,
        postId: store.getters.getCart.postId
          ? store.getters.getCart.postId
          : route.query.postId?.toString(),
        brandId: store.getters.getBrand.brandId,
        branchId: store.getters.getBrand.branchId,
        socialSalesChannel: store.getters.getSaleChannel,
        products: store.getters.getCart.products,
        freeProducts: store.getters.getCart.freeProducts,
        productDiscount: store.getters.getCart.productDiscount,
        SessionId: route.query.sessionId?.toString(),
        note: "",
      };

      //handle product in cart
      if (!store.getters.getCart.products.some((el) => el.id === product.id)) {
        cartDetail.value.products.push({
          id: product.id,
          quantity: quantity,
        });
      } else {
        cartDetail.value.products[
          store.getters.getCart.products.findIndex((el) => el.id === product.id)
        ].quantity += quantity;
      }

      await onSaveCart(cartDetail.value, cartFlag);
    };

    const onSaveCart = async (cart: UserCart, cartFlag: number) => {
      await cartService.value
        .onSaveCart(cart)
        .then(async (res: Cart) => {
          if (res) {
            store.dispatch(CartActionTypes.SET_CART, res);
            if (cartFlag == 1) {
              router.push(
                `/${route.params.initialId}/checkout?sessionId=${route.query.sessionId}`
              );
            } else {
              if (computed(() => store.getters.getIsInProductDetail).value) {
                onCloseBrowser();
              } else {
                getProductDetail();
              }
            }
          }
        })
        .catch((error) => {
          getProductDetail();
          if (error.message) {
            modalMessage.value = error.message;
          } else {
            modalMessage.value = error;
          }
          modalAlertError.value?.setShowModalError(true);
        });
    };

    const onCloseBrowser = async () => {
      if (store.getters.getPlatform == 1) {
        await getProductDetail();
        liff.closeWindow();
      } else if (store.getters.getPlatform == 2) {
        await getProductDetail();
        onCloseFBBrowser();
      } else {
        await getProductDetail();
      }
    };

    onMounted(() => {
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      getProductDetail();
    });

    return {
      productDetail,
      modalMessage,
      modalAlertError,
      onAddProductToCart,
      beforeAddProductTocart,
      onHideModalError,
    };
  },
  methods: {},
});
