
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    modalMessage: {
      required: true,
      type: String,
    },
  },
  emits: ["onHideModalError"],
  setup(props, { emit }) {
    const isShowModal = ref<boolean>(false);

    const setShowModalError = (value: boolean) => {
      isShowModal.value = value;
    };

    const onHideModalError = () => {
      emit("onHideModalError");
    };

    return { isShowModal, setShowModalError, onHideModalError };
  },
});
