
import { ProductDetailResponse, ProductDetailResponseDetail } from "@/types/product/ProductDetail.interface";
import ResponseData from "@/types/response/Response.interface"
import axios from "../instance/AxiosInstance"


export default class ProductDetailService {

    async getProductDetail(productId: number): Promise<ProductDetailResponseDetail> {
        return await axios.get('/product/productdetail/' + productId)
            .then((res: ResponseData<ProductDetailResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as ProductDetailResponseDetail
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }


}
