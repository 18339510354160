import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Galleria = _resolveComponent("Galleria")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Galleria, {
      value: _ctx.productImages,
      numVisible: 7,
      showThumbnails: false,
      circular: true,
      changeItemOnIndicatorHover: true,
      showItemNavigators: true
    }, {
      item: _withCtx((slotProps) => [
        _createElementVNode("div", {
          class: "center-cropped",
          style: _normalizeStyle({
            'background-image': 'url(' + slotProps.item.imageUrl + ')',
          })
        }, null, 4)
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}