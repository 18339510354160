
import { ProductDetailImage } from "@/types/product/ProductDetail.interface";
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  props: {
    productImages: {
      required: true,
      type: Array as PropType<ProductDetailImage[]>,
    },
  },
  setup() {
    const displayModalImage = ref<boolean>(false);
    return { displayModalImage };
  },
});
