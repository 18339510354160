import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductDetailPanel = _resolveComponent("ProductDetailPanel")!
  const _component_ModalAlertError = _resolveComponent("ModalAlertError")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      (_ctx.productDetail)
        ? (_openBlock(), _createBlock(_component_ProductDetailPanel, {
            key: 0,
            productDetail: _ctx.productDetail,
            onOnAddProductToCart: _ctx.beforeAddProductTocart
          }, null, 8, ["productDetail", "onOnAddProductToCart"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ModalAlertError, {
      modalMessage: _ctx.modalMessage,
      onOnHideModalError: _ctx.onHideModalError,
      ref: "modalAlertError"
    }, null, 8, ["modalMessage", "onOnHideModalError"])
  ]))
}