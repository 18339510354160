export const onCloseFBBrowser = function () {
    // eslint-disable-next-line no-undef
    MessengerExtensions.requestCloseBrowser(
        function success() {
            // webview closed
        },
        function error(err) {
            // an error occurred
            console.log(err);
        }
    );
}