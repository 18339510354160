import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03d3eef0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel p-d-flex p-flex-column" }
const _hoisted_2 = { class: "p-col-3 p-my-auto" }
const _hoisted_3 = { class: "p-d-flex h-100" }
const _hoisted_4 = { class: "text-gray p-my-auto f-size-16 f-medium" }
const _hoisted_5 = { class: "p-px-0 option-section p-flex-row p-col-9 p-text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (attribute, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "p-my-1 p-grid",
          key: `attribute-${attribute}`
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(attribute.label), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attribute.option, (option, index2) => {
              return (_openBlock(), _createBlock(_component_Button, {
                key: `option-${index}-${index2}`,
                disabled: option.isOutOfStock,
                class: _normalizeClass([
              'p-my-1 p-mr-1 btn-option p-py-1',
              _ctx.selectedAttr[attribute.sortOrder].optionId == option.id &&
              !option.isOutOfStock
                ? 'btn-outline-primary2'
                : 'bg-gray-3 border-0 text-gray ',
            ]),
                onClick: ($event: any) => (_ctx.handleCheckOptionAttribute(attribute.id, option.id)),
                label: option.label
              }, null, 8, ["disabled", "class", "onClick", "label"]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}